<template>
  <header>
    <div class="header_top">
      <div class="left-block">
        <a href="tel:+380970530842"
          ><img src="@/assets/phone-black.svg" alt="" />
          <div class="text">0970530842</div></a
        >
        <a href="#"
          ><img src="@/assets/mail-black.svg" alt="" />
          <div class="text">pherchyk@gmail.com</div></a
        >
      </div>
      <div class="right-block">
        <a href="https://t.me/feshchuk_ps"
          ><img src="@/assets/telegram-black.svg" alt=""
        /></a>
        <a href="https://www.facebook.com/pherchyk"
          ><img src="@/assets/facebook-black.svg" alt=""
        /></a>
        <a href="https://www.instagram.com/feshchuk.ps/"
          ><img src="@/assets/instagram-black.svg" alt=""
        /></a>
      </div>
    </div>
    <div class="header_menu">
      <ul>
        <li class="text" @click="scrollToSection('section1')">Про Мене</li>
        <li class="text" @click="scrollToSection('section2')">
          Що ви отримаєте
        </li>
        <li class="text" @click="scrollToSection('section3')">
          Мої досягнення
        </li>
        <li class="text" @click="scrollToSection('section4')">Освіта</li>
        <!-- <li class="text">Вартість</li>
        <li class="text">Контакти</li> -->
      </ul>
    </div>
  </header>
  <div class="about" id="section1">
    <div class="about_background"></div>
    <div class="about_container">
      <div class="about_photo">
        <img alt="feshchuk" src="@/assets/tanya.jpg" />
      </div>
      <div class="about_text">
        <h3 class="text">ПСИХОЛОГ</h3>
        <h1 class="text">Фещук Тетяна</h1>
        <p class="text">
          Розкрий свій потенціал разом зі мною! 🌟 Привіт! Я - практичний
          психолог з магістерською освітою і готова допомогти тобі у досягненні
          успіху та психологічного комфорту. Як студентка магістратури з
          останнім роком навчання, я маю свіжий погляд на сучасні виклики та
          можливості. Сприяю розвитку особистості, підтримую впевненість та
          досягнення цілей. Запрошую на особисті консультації та спільний шлях
          до успіху! 🤝 Залишайте запити в приватних повідомленнях. Вперед до
          психологічного зростання разом!
        </p>
      </div>
    </div>
  </div>
  <div class="info sign_up">
    <h2 class="text">Зміни своє життя прямо зараз!</h2>
    <h3 class="text">Запишись на першу консультацію до психолога</h3>
    <div class="button text" @click="showModal = true">Запис</div>
    <a class="button text" href="https://t.me/feshchuk_ps">
      Написати в телеграм
    </a>
  </div>

  <div class="you_get" id="section2">
    <div class="you_get_background"></div>
    <h2 class="text">Що ви отримаєте?</h2>
    <div class="you_get_blocks">
      <div class="you_get_item">
        <img class="big_ico" src="@/assets/understand.svg" alt="" />
        <h3 class="text">Поглиблене розуміння себе</h3>
        <p class="text">
          Ми розглянемо ваші думки, емоції та переживання для кращого розуміння
          ваших потреб і бажань.
        </p>
      </div>
      <div class="you_get_item">
        <img class="big_ico" src="@/assets/strategy.svg" alt="" />
        <h3 class="text">Розробка практичних стратегій</h3>
        <p class="text">
          Разом ми створимо індивідуальний план дій, який допоможе вам подолати
          виклики та здобути більше впевненості.
        </p>
      </div>
      <div class="you_get_item">
        <img class="big_ico" src="@/assets/empathy.svg" alt="" />
        <h3 class="text">Підтримка і емпатія</h3>
        <p class="text">
          Ви зможете відкрито спілкуватися, відчуваючи, що вас розуміють та
          підтримують.
        </p>
      </div>
      <div class="you_get_item">
        <img class="big_ico" src="@/assets/privacy.svg" alt="" />
        <h3 class="text">Конфіденційність</h3>
        <p class="text">
          Всі ваші розмови зі мною залишаться абсолютно конфіденційними.
        </p>
      </div>
    </div>
  </div>
  <div class="achievements" id="section3">
    <h2 class="text">Мої досягення</h2>
    <div class="achievements_text">
      <p class="text">
        <span>
          Я хочу поділитися з вами кількома важливими кроками у моєму
          професійному житті як практичного психолога. 🎓</span
        >
        <span>
          1️⃣ Індивідуальна терапія: щоб краще зрозуміти себе та покращити свої
          навички, я самостійно пройшла індивідуальну терапію. Це був
          неймовірний досвід самопізнання, який допоміг мені стати більш
          емпатичною та відкритою.</span
        ><span>
          2️⃣ Навчання у магістратурі: в даний час я продовжую свою освітню
          подорож у магістратурі за спеціальністю "Практична психологія". Нові
          знання та теорії надихають мене знаходити більш ефективні інструменти
          для підтримки моїх клієнтів. </span
        ><span
          >3️⃣ Членство в Українській психологічній асоціації: я відчуваю
          гордість, бути прийнятою в ряди Української психологічної асоціації,
          де маю можливість обмінюватися досвідом з колегами та вдосконалюватися
          в своїй професії.</span
        ><span>
          4️⃣ Кодекс етики психолога: всі мої дії та рішення ґрунтуються на
          принципах професійної етики. Для мене важливо забезпечити
          конфіденційність, повагу, і зберегти безпеку для моїх клієнтів.
          Звертайтесь, і разом ми знайдемо рішення, що приведуть вас до
          психологічної гармонії. 🤗</span
        >
      </p>
      <div class="slider">
        <CaruselItem />
      </div>
    </div>
  </div>
  <div class="info question">
    <h2 class="text">У вас є питання ?</h2>
    <h3 class="text">Поставте запитання спеціалісту</h3>
    <a class="button text" href="https://t.me/feshchuk_ps"
      >Поставити запитання</a
    >
    <div class="button text" @click="showModal = true">Залишити заявку</div>
  </div>

  <div class="education" id="section4">
    <div class="education_background"></div>
    <div class="education_block">
      <h3 class="text">Освіта</h3>
      <p class="text">
        <span>📚 Моя освіта: шлях до професійної майстерності! 🌟 </span>
        <span
          >🎓 Магістратура: наразі я є студенткою денного відділення
          Маріупольського державного університету, останнього курсу
          магістратури. Моя спеціальність - практична психологія. Впродовж
          навчання я отримала важливі знання та досвід, які стали невід'ємною
          частиною мого професійного росту. </span
        ><span
          >🔍 Додаткове навчання: я завжди прагну вдосконалюватися і
          розвиватися. Тому я пройшла додаткові навчання з основ
          когнітивно-поведінкової терапії (КПТ) в Міжнародному інституті
          психології. Ці знання допомагають мені знаходити ефективні підходи у
          роботі з клієнтами.</span
        >
        <span>
          🤝 Активна участь у навчаннях: я є членом Української психологічної
          асоціації, де беру активну участь у різноманітних навчаннях, тренінгах
          та вебінарах. Моя участь демонструє мою зацікавленість у розвитку і
          стеження за останніми тенденціями у світі психології.</span
        >
        <span
          >🏆 Сертифікати професійного росту: на моєму особистому сайті ви
          зможете знайти сертифікати з відвіданих тренінгів та вебінарів, які
          підтверджують мій професійний рівень та зобов'язання до постійного
          навчання. </span
        ><span
          >Я завжди стараюся давати своєму професійному розвитку найвищий
          пріоритет, щоб надавати максимальну підтримку та допомогу своїм
          клієнтам. Я вірю, що найкращий психолог - той, хто ніколи не перестає
          навчатися! Залишайтеся зі мною, і разом ми знайдемо ключі до вашого
          психологічного благополуччя та успіху! 🤗</span
        >
      </p>
    </div>
    <div class="education_block">
      <h3 class="text">Напрями Діяльності</h3>
      <p class="text">
        <span>
          Моя мета як психолога - допомогти вам знайти рішення і зміцнити свою
          психологічну стійкість у будь-яких життєвих обставинах. Ви
          заслуговуєте на щасливе життя, а я тут, щоб вас підтримати в цьому
          шляху! 🤝 </span
        ><span
          >🧠 Самооцінка: здатність вірити у себе - ключ до успіху! Разом ми
          будемо працювати над збільшенням самоприйняття та розумінням своїх
          сильних сторін.</span
        ><span>
          🗺️ Пошук життєвого шляху: я допоможу вам розкрити ваш потенціал та
          визначити ваші мрії та цілі. Разом ми знайдемо шлях, який вас надихає
          та допоможе досягти успіху. </span
        ><span
          >❤️ Особисті відносини: взаємини з іншими - важлива частина нашого
          життя. Я допоможу вам розвинути навички спілкування та зміцнити
          зв'язки з близькими.</span
        >
        <span
          >🕊️ Життя в умовах війни: я розумію, як важко жити в умовах воєнного
          часу. Моя підтримка допоможе вам знайти внутрішній мир та навчитися
          справлятися з емоціями.</span
        >
      </p>
    </div>
  </div>
  <footer>
    <div class="footer">
      <div class="left-block">
        <a href="tel:+380970530842"
          ><img src="@/assets/phone-white.svg" alt="" />
          <div class="text">0970530842</div></a
        >
        <a href="#"
          ><img src="@/assets/mail-white.svg" alt="" />
          <div class="text">pherchyk@gmail.com</div></a
        >
      </div>
      <div class="right-block">
        <a href="https://t.me/feshchuk_ps"
          ><img src="@/assets/telegram-white.svg" alt=""
        /></a>
        <a href="https://www.facebook.com/pherchyk"
          ><img src="@/assets/facebook-white.svg" alt=""
        /></a>
        <a href="https://www.instagram.com/feshchuk.ps/"
          ><img src="@/assets/instagram-white.svg" alt=""
        /></a>
      </div>
    </div>
  </footer>
  <FeedbackModal v-if="showModal" @close="showModal = false" />
</template>

<script>
import FeedbackModal from "@/components/FeedbackModal.vue";
import CaruselItem from "@/components/CaruselItem.vue";
export default {
  name: "App",
  data() {
    return {
      showModal: false,
    };
  },
  components: {
    FeedbackModal,
    CaruselItem,
  },
  methods: {
    scrollToSection(sectionId) {
      this.$scrollTo(document.querySelector(`#${sectionId}`), 500);
    },
  },
};
</script>

<style lang="scss">
$gray: #000000aa;
$black: #000000;
$white: #ffffff;
$blue: #807efe;
h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
li {
  margin: 0;
  padding: 0;
  color: $black;
  font-weight: 600;
  font-size: 16px;
}
body {
  margin: 0;
  background: $white;
  a {
    color: $black;
    text-decoration: none;
  }
  .text {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: $black;
    text-decoration: none;
    font-weight: 600;
  }
  header {
    position: fixed;
    width: 100%;
    opacity: 0.98;
    top: 0;
    z-index: 2;
  }
  .header_top {
    display: flex;
    justify-content: space-between;
    padding: 15px 100px;
    margin: 0;
    align-items: center;
    background: $white;
    .left-block {
      display: flex;
      align-items: center;
      a {
        display: flex;
        align-items: center;
        margin-right: 20px;
        img {
          margin-right: 15px;
        }
      }
    }
    .right-block {
      display: flex;
      a {
        display: flex;
        align-items: center;
        margin-right: 20px;
      }
    }
  }
  .header_menu {
    background: $gray;
    padding: 0 100px;
    ul {
      display: flex;
      padding: 0;
      margin: 0;
      .text {
        color: $white;
        text-transform: uppercase;
        text-decoration: none;
        list-style-type: none;
        letter-spacing: 1.5px;
      }
      li {
        margin-right: 40px;
        padding: 30px 0;
        font-weight: 700;
        cursor: pointer;
      }
    }
  }
  .about {
    display: flex;
    height: calc(100vh - 64px);
    margin-top: 64px;
    position: relative;
    .about_background {
      z-index: -1;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: url("@/assets/office.jpg");
      background-size: cover;
      background-repeat: no-repeat;
      opacity: 0.175;
    }
    .about_container {
      display: flex;
      align-items: center;
      .about_photo {
        padding: 15px 100px;
        img {
          border-radius: 50%;
          margin: 50px 0;
        }
      }
      .about_text {
        padding: 15px 100px;
        background: rgba(255, 255, 255, 0.1);
        .text {
          text-align: left;
        }
        h3 {
          font-size: 24px;
          font-weight: 700;
          margin: 0;
        }
        h1 {
          font-size: 72px;
          font-weight: 900;
          margin: 0;
        }
        p {
          font-size: 20px;
          line-height: 32px;
        }
      }
    }
  }
  .button {
    border: 2px solid $white;
    border-radius: 40px;
    display: inline-block;
    padding: 10px 40px;
    margin: 5px;
    font-size: 24px;
    font-weight: 700;
    cursor: pointer;
    margin-top: 20px;
  }
  .info {
    background-color: $blue;
    padding: 50px 100px;
    text-align: center;
    .text {
      color: $white;
    }
    h2 {
      font-size: 28px;
      font-weight: 700;
      margin-bottom: 24px;
    }
    h3 {
      font-size: 16px;
      font-weight: 500;
    }
  }
  .you_get {
    height: 100vh;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .you_get_background {
      z-index: -1;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: url("@/assets/people.jpg");
      background-size: cover;
      background-repeat: no-repeat;
      opacity: 0.7;
    }
    h2 {
      font-size: 48px;
      font-weight: 700;
      margin-bottom: 24px;
      text-align: left;
      padding: 50px 100px;
    }
    .you_get_blocks {
      display: flex;
      padding: 50px 50px;
      .you_get_item {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0 25px;
        flex: 1;
        flex-basis: 0;
        margin-bottom: 100px;
        .big_ico {
          width: 128px;
          margin-bottom: 10px;
        }
        h3.text {
          font-size: 28px;
          line-height: 36px;
          margin-bottom: 20px;
        }
        .text {
          font-size: 16px;
          line-height: 24px;
          font-weight: 700;
          letter-spacing: 1.5px;
        }
      }
    }
  }
  .achievements {
    background-color: $black;
    padding-top: 84px;
    h2 {
      color: $white;
      text-align: left;
      font-size: 48px;
      font-weight: 700;
      margin-bottom: 24px;
      padding: 50px 100px;
    }
    .achievements_text {
      display: flex;
      .text {
        color: $white;
        span {
          padding: 0px 100px;
          margin-top: 20px;
          display: block;
          text-align: left;
          font-size: 18px;
          line-height: 30px;
          font-weight: 500;
        }
      }
    }
    .slider {
      max-width: 800px;
      height: 800px;
      background-color: #222222;
    }
  }
  .education {
    min-height: 100vh;
    position: relative;
    padding: 50px 100px;
    display: flex;
    .education_background {
      z-index: -1;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: url("@/assets/books.jpg");
      background-size: cover;
      background-repeat: no-repeat;
    }
    .education_block {
      padding: 50px;
      border-radius: 50px;
      background: rgba(255, 255, 255, 0.9);
      flex: 1;
      flex-basis: 0;
      margin: 50px;
      .text {
        margin-top: 20px;
        text-align: left;
        font-size: 18px;
        line-height: 30px;
        font-weight: 500;
        span {
          display: block;
          margin-top: 20px;
        }
      }
      h3.text {
        font-size: 48px;
        line-height: 64px;
        font-weight: 700;
        margin-bottom: 40px;
      }
    }
  }
  .footer {
    display: flex;
    justify-content: space-between;
    padding: 15px 100px;
    margin: 0;
    align-items: center;
    background-color: $blue;
    .left-block {
      display: flex;
      align-items: center;
      a {
        display: flex;
        align-items: center;
        margin-right: 20px;
        .text {
          color: $white;
        }
        img {
          margin-right: 15px;
        }
      }
    }
    .right-block {
      display: flex;
      color: $white;
      a {
        display: flex;
        align-items: center;
        margin-right: 20px;
      }
    }
  }
}
@media (max-width: 1600px) {
  body {
    .about {
      .about_background {
      }
      .about_container {
        .about_photo {
          img {
            max-width: 480px;
          }
        }
        .about_text {
          h1 {
            font-size: 64px;
          }
          p {
            font-size: 18px;
          }
        }
      }
    }
  }
}
@media (max-width: 1367px) {
  body {
    .about {
      .about_background {
      }
      .about_container {
        .about_photo {
          max-width: 280px;
          img {
            max-width: 380px;
          }
        }
        .about_text {
          h1 {
            font-size: 48px;
          }
          p {
            font-size: 16px;
          }
        }
      }
    }
    .you_get {
      height: auto;
      .you_get_blocks {
        flex-wrap: wrap;
        .you_get_item {
          min-width: 300px;
        }
      }
    }
    .achievements {
      .achievements_text {
        flex-direction: column;
        .slider {
          margin-top: 40px;
          min-width: 100%;
        }
      }
    }
    .education {
      padding: 50px 100px;
      .education_block {
        padding: 30px;
        margin: 10px;
        h3.text {
          font-size: 26px;
          line-height: 32px;
          font-weight: 700;
          margin-bottom: 40px;
        }
      }
    }
  }
}
@media (max-width: 1050px) {
  body {
    .header_menu {
      padding: 0 20px;
    }
    .about {
      margin-top: 140px;
      height: auto;
      .about_container {
        flex-direction: column;
        .about_photo {
          max-width: 100%;
          max-width: 360px;
          img {
            max-width: 100%;
          }
        }
        .about_text {
          h1 {
            font-size: 64px;
          }
          p {
            font-size: 20px;
          }
          margin-bottom: 40px;
        }
      }
    }
    .you_get {
      .you_get_blocks {
        flex-direction: column;
      }
    }
    .education {
      padding: 50px 100px;
      flex-direction: column;
      .education_block {
        padding: 30px;
        margin: 10px;
        h3.text {
          font-size: 26px;
          line-height: 32px;
          font-weight: 700;
          margin-bottom: 40px;
        }
      }
    }
  }
}
@media (max-width: 750px) {
  body {
    .header_top {
      padding: 15px 20px;
      .left-block {
        flex-direction: column;
        align-items: flex-start;
        a {
          margin-right: 0px;
          margin: 10px;
        }
      }
    }
    .header_menu {
      display: none;
    }
    .about {
      .about_container {
        .about_photo {
          padding: 0px 0px;
          max-width: 300px;
        }
        .about_text {
          padding: 15px 20px;
        }
      }
    }
    .info {
      padding: 50px 20px;
    }
    .you_get {
      h2 {
        padding: 50px 20px;
      }
      .you_get_blocks {
        padding: 50px 20px;
      }
    }
    .education {
      padding: 50px 20px;
      flex-direction: column;
      .education_block {
        padding: 30px;
        margin: 10px;
        h3.text {
          font-size: 26px;
          line-height: 32px;
          font-weight: 700;
          margin-bottom: 40px;
        }
      }
    }
    .achievements {
      h2 {
        padding: 50px 20px;
      }
      .achievements_text {
        .text {
          color: $white;
          span {
            padding: 0px 20px;
          }
        }
      }
    }
    .education {
      padding: 50px 20px;
      .education_block {
        padding: 20px;
        margin: 20px;
      }
    }
    .footer {
      padding: 15px 20px;
      .left-block {
        flex-direction: column;
        align-items: flex-start;
        a {
          margin-right: 0px;
          margin: 10px;
        }
      }
    }
  }
}
@media (max-width: 450px) {
  body {
    .header_top {
      padding: 20px 0px;
      .left-block {
        img {
          width: 24px;
        }
      }
      .right-block {
        img {
          width: 24px;
        }
        //flex-direction: column;
        // align-items: flex-start;
        a {
          margin-right: 0px;
          margin: 10px;
        }
      }
    }
    .you_get {
      h2 {
        font-size: 32px;
        font-weight: 700;
        margin-bottom: 24px;
        text-align: left;
        padding: 40px 20px;
      }
      .you_get_blocks {
        padding: 15px 15px;
        .you_get_item {
          margin-bottom: 35px;
        }
      }
    }
    .about {
      .about_container {
        .about_photo {
          img {
            max-width: 200px;
            margin: 10px 0;
          }
        }
        .about_text {
          h1 {
            font-size: 32px;
          }
          p {
            font-size: 14px;
          }
        }
      }
    }
    .education {
      padding: 25px 0px;
    }
    .footer {
      padding: 20px 0px;
      .left-block {
        flex-direction: column;
        align-items: flex-start;
        a {
          margin-right: 0px;
          margin: 10px;
        }
        img {
          width: 24px;
        }
      }
      .right-block {
        img {
          width: 24px;
        }
      }
    }
  }
}
</style>
