<template>
  <div class="modal_background" @click="closeModal()">
    <div class="modal" @click.stop>
      <form>
        <div class="form_item">
          <label for="name">Ім'я</label>
          <input name="name" v-model="name" type="text" />
        </div>
        <div class="form_item">
          <label for="phone">Телефон</label>
          <input name="phone" v-model="phone" type="text" />
        </div>
        <div
          class="button text"
          @click="handleSubmit()"
          :class="{ disabled: !isValid }"
          :disabled="!isValid"
        >
          Надіслати
        </div>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      name: "",
      phone: "",
    };
  },
  methods: {
    handleSubmit() {
      if (!this.isValid) return;
      this.sendInfo();
      this.closeModal();
    },
    closeModal() {
      this.$emit("close");
    },
    async sendInfo() {
      try {
        const url = "https://formspree.io/f/xrgwepeg";
        const data = {
          name: this.name,
          phone: this.phone,
        };
        const response = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        });

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        this.name = "";
        this.email = "";
        this.message = "";
      } catch (error) {
        console.error(error);
      }
    },
  },
  computed: {
    isValid() {
      return (
        this.name.length > 3 &&
        this.name.length < 20 &&
        this.phone.length > 8 &&
        this.phone.length < 20
      );
    },
  },
};
</script>

<style lang="scss">
$gray: #000000aa;
$black: #000000;
$white: #ffffff;
$blue: #807efe;
.button {
  border: 2px solid $black;
  background: $blue;
  border-radius: 40px;
  display: inline-block;
  padding: 10px 40px;
  font-size: 24px;
  font-weight: 700;
  cursor: pointer;
  margin-top: 20px;
  &.text {
    color: $white;
  }
  &.disabled {
    opacity: 0.2;
  }
}
.modal_background {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.3);
  top: 0;
  left: 0;
  z-index: 50;
  display: flex;
  align-items: center;
  justify-content: center;
  .modal {
    width: 320px;
    background: white;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 30px;
    border-radius: 15px;
    .form_item {
      display: flex;
      flex-direction: column;
      margin-top: 10px;
      input {
        font-size: 16px;
        border: 2px solid $blue;
        padding: 10px;
        border-radius: 10px;
      }
    }
  }
}
</style>
