<template>
  <carousel
    id="gallery"
    :items-to-show="1"
    :wrap-around="false"
    v-model="currentSlide"
  >
    <slide v-for="slide in 13" :key="slide">
      <div class="slide">
        <img :src="sliders[slide]" alt="" />
      </div>
    </slide>
  </carousel>
  <Carousel
    id="thumbnails"
    :items-to-show="4"
    :wrap-around="true"
    v-model="currentSlide"
    ref="carousel"
  >
    <Slide v-for="slide in 10" :key="slide">
      <div class="carousel__item" @click="slideTo(slide - 1)">
        <div class="mini-slide">
          <img :src="sliders[slide]" alt="" />
        </div>
      </div>
    </Slide>
  </Carousel>
</template>

<script>
import "vue3-carousel/dist/carousel.css";
import { Carousel, Slide } from "vue3-carousel";

export default {
  name: "App",
  components: {
    Carousel,
    Slide,
  },
  data() {
    return {
      currentSlide: 0,
      sliders: [
        "/certificates/photo1.jpg",
        "/certificates/photo2.jpg",
        "/certificates/photo3.jpg",
        "/certificates/photo4.jpg",
        "/certificates/photo5.jpg",
        "/certificates/photo6.jpg",
        "/certificates/photo7.jpg",
        "/certificates/photo8.jpg",
        "/certificates/photo9.jpg",
        "/certificates/photo10.jpg",
        "/certificates/photo11.jpg",
        "/certificates/photo12.jpg",
        "/certificates/photo13.jpg",
        "/certificates/photo14.jpg",
      ],
    };
  },
  methods: {
    slideTo(val) {
      this.currentSlide = val;
    },
  },
};
</script>
<style lang="scss">
$gray: #000000aa;
$black: #000000;
$white: #ffffff;
$blue: #807efe;
.slide {
  height: 600px;
  img {
    max-width: 100%;
    max-height: 100%;
  }
}
.mini-slide {
  height: 100px;
  margin: 5px;
  img {
    max-width: 100%;
    max-height: 100%;
  }
}
</style>
